
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import {Getter} from "vuex-class";
import RoundRadios from "@/components/RoundRadios.vue";
import {IUserDB} from "@/types";

@Component({
    name: "OrderCreationDetails",
    components: {RoundRadios}
})
export default class OrderCreationDetails extends mixins(OrderCreationStepMixin) {

    @Getter('isCompany') isCompany!: any;
    @Getter('isAgency') isAgency!: any;
    @Getter('userContext') userContext!: string;

    get displayed() {
        return [
            {
                label: this.$t('page.orderCreation.steps.details.cooperation.' + this.userContext),
                _id: true
            },
            {
                label: this.$t('page.orderCreation.steps.details.trust.' + this.userContext),
                _id: false
            }
        ];
    }

    get selected() {
        return [this.innerValue.presentationMode || false];
    }

    onSelect(selected: string[]) {
        this.innerValue.presentationMode = selected[0];
    }

    clientValidation() {
        this.innerValue.clientValidation = !this.innerValue.clientValidation;
        this.$forceUpdate();
    }
    autoConfirmation() {
        this.innerValue.autoConfirmation = !this.innerValue.autoConfirmation;
        this.$forceUpdate();
    }
}
