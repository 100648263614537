
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import Tile from "@/components/tileContent/Tile.vue";
import OrderTile from "@/components/tileContent/OrderTile.vue";
import { ICompanyDB, StoreAction } from "@/types";
import { Action, Getter } from "vuex-class";
import ClientForm from "@/components/client/ClientForm.vue";
import CompanyTile from "@/components/tileContent/CompanyTile.vue";
import { ROUTES } from "@/data";
import clonedeep from "lodash.clonedeep";
import { capitalize } from "@/helpers/commons";

@Component({
    name: "OrderCreationClient",
    components: {CompanyTile, ClientForm, OrderTile, Tile}
})
export default class OrderCreationClient extends mixins(OrderCreationStepMixin) {
    $refs!: {
        container: any
    };
    companies: ICompanyDB[] = [];
    lastCompanies: ICompanyDB[] = [];
    showCompanyForm = false;

    query = "";
    timeout:any = 0;

    capitalize = capitalize;

    @Action('actions/lastCompaniesMissions') lastCompaniesMissions!: StoreAction;
    @Action('actions/searchCompanies') searchCompanies!: StoreAction;
    @Action('actions/createCompany') createCompany!: StoreAction;

    @Getter('hasSalesforce') hasSalesforce!: boolean;

    @Watch('query')
    onQuery() {
        this.loading = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.search();
        }, 300);
    }

    toggleClientForm() {
        this.showCompanyForm = !this.showCompanyForm;
        this.innerValue.createNewClient = this.showCompanyForm;
        this.innerValue.newClient = {};

        if (!this.showCompanyForm) {
            this.$refs.container.$el.scrollTop = 0;
        }
    }

    selectCompany(company: ICompanyDB) {
        this.innerValue.client = company;
        this.innerValue.clientId = company._id;
    }

    goToClient() {
        this.$router.push({name: ROUTES.APP.CLIENT, params: {companyId: this.innerValue.clientId}});
    }

    async search() {
        this.loading = true;
        try {
            if (this.query) {
                this.companies = (await this.searchCompanies(this.query)).slice(0, 50);
            } else {
                this.companies = clonedeep(this.lastCompanies)
            }
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async init() {
        this.loading = true;
        try {
            this.lastCompanies = await this.lastCompaniesMissions();
            this.companies = clonedeep(this.lastCompanies);
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
