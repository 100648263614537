
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {IMission} from "@/types";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";

@Component({
    name: "OrderCreationStepMixin"
})
export default class OrderCreationStepMixin extends mixins(VModel) {
    @Prop() step!: any;
    @Prop() mission!: IMission;
    @Prop() errors!:any;
    @Prop() globalErrors!:any;

    loading = false;

    get scrollOptions() {
        return {
            suppressScrollX: true,
        }
    }

}
