
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {LabelValue} from "@/types";
import {Getter} from "vuex-class";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import NewIcon from "@/components/NewIcon.vue";

@Component({
    name: "Pagination",
    components: {SelectAutocomplete, NewIcon}
})
export default class Pagination extends mixins(VModel) {
    $refs!: {
        totalPage: any
    };
    search = "";
    jobs:any = [];
    focus = false;
    operator = "or";

    @Prop() filtered!: any;
    @Prop() selectedJobs!: any;

    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('getJobOption') getJobOption!: any;

    get limitOptions() {
        return [
            {
                label: 10,
                value: 10
            },
            {
                label: 20,
                value: 20
            },
            {
                label: 30,
                value: 30
            },
            {
                label: 40,
                value: 40
            },
            {
                label: 50,
                value: 50
            }
        ];
    }

    get totalPage() {
        let value = Math.floor(this.innerValue.total / this.innerValue.limit);

        if (isNaN(value)) {
            return 1;
        }
        return value || 1;
    }

    get pagesOptions() {
        let i = 0, len = this.totalPage;
        const arr: LabelValue[] = [];

        while (i < len) {
            arr.push({
                label: (i + 1).toString(),
                value: i.toString()
            })
            ++i;
        }

        return arr;
    }

    get total() {
        return this.filtered.length + '/' + this.innerValue.total;
    }

    get jobsLabel() {
        if (this.selectedJobs.length === 1) {
            return this.selectedJobs[0].label;
        }
        return this.$t('page.orderCreation.steps.selection.nJob', {jobNb: this.selectedJobs.length});
    }

    get filteredJobs() {
        return this.jobOptions.filter((a:any) => this.selectedJobs.indexOf(a.value) === -1);
    }

    get operatorOptions() {
        return [
            {
                label: 'Ou',
                value: "or",
            },
            {
                label: "Et",
                value: "and"
            }
        ]
    }
}
