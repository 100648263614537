
import {Component, Vue, Prop} from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";
import {IAgencyDB, LabelValue} from "@/types";
import AgencyPreview from "@/components/AgencyPreview.vue";
import {formattedAddress} from "@/helpers/commons";
import {Getter} from "vuex-class";

@Component({
    name: "AgencyTile",
    components: {AgencyPreview, Tile}
})
export default class AgencyTile extends Vue {
    @Prop() agency!: IAgencyDB;
    @Prop() hasAskAgency!: boolean;
    @Prop() missionJobs!: string[];
    @Prop() sendQuote!: any;

    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('getJob') getJob!: any;

    displayMini = false;

    apiUrl:string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    get picture() {
        return this.apiUrl + this.agency.logo;
    }

    get address() {
        return formattedAddress(this.agency.address);
    }

    get isFull() {
        return this.agency.subscriptionType === "full";
    }

    get domains() {
        const names:Set<string> = new Set<string>();

        if (this.agency.survey?.developingBussinessSectors) {

            this.agency.survey.developingBussinessSectors.forEach((dbs: any) => {
                names.add(dbs.label);
            });
        }

        if (this.agency.survey?.mainBusinessSectors) {
            this.agency.survey.mainBusinessSectors.forEach((dbs: any) => {
                names.add(dbs.label);
            });
        }

        return names.size > 0 ? [...names].join(', ') : '-';
    }

    get workers() {
        if (this.agency.workers) {
            const keys = Object.keys(this.agency.workers);
            if (this.agency.workers && keys?.length) {
                return keys.filter((key: string) => {
                    return this.missionJobs.includes(key);
                }).map((key:string) => {
                    return {
                        jobName: this.getJob(key)?.name || '',
                        count: this.agency.workers[key],
                        jobId: key
                    }
                });
            }
        }
    }

    get departments() {
        if (this.agency.delegationDepartments?.length) {
            return this.agency.delegationDepartments.join(', ')
        }
        if (this.agency.address?.zip) {
            return this.agency.address?.zip.substr(0, 2);
        }
        return '-';
    }

    openMiniRead() {
        this.displayMini = true;
    }
}
