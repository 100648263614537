
    import {Component, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "AlertModal"
    })
    export default class AlertModal extends mixins(ModalMixin) {
        name = "AlertModal";

        @Prop(String) title: any;
        @Prop(String) text: any;
        @Prop(String) closeLabel: any;

        get closeString() {
            return this.closeLabel? this.closeLabel:this.$t('modals.global.close');
        }
    }
