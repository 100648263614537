
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import moment from "moment-timezone";
import { capitalize, getFormattedHour } from "@/helpers/commons";
import { DataSet, LabelValue, MissionPeriod, MissionPosition, MissionPositionFront, TimeSlot } from "@/types";
import InlineField from "@/components/InlineField.vue";
import clonedeep from "lodash.clonedeep";
import RoundRadio from "@/components/RoundRadio.vue";
import MissionPeriodLine from "@/components/MissionPeriodLine.vue";
import Row from "../grid/Row.vue";

@Component({
    name: "OrderCreationPeriods",
    components: { MissionPeriod: MissionPeriodLine, RoundRadio, InlineField, Row },
})
export default class OrderCreationPeriods extends mixins(OrderCreationStepMixin) {
    innerValue!: any;
    // @Watch('innerValue', {deep: true})
    // onInner() {
    //     if (!this.innerValue.periods || !this.innerValue.periods.length) {
    //         this.addPeriod();
    //     }
    // }

    getDefaultSlot() {
        return [
            {
                from: getFormattedHour(930),
                to: getFormattedHour(1700),
            },
        ];
    }

    removePeriod(index: number) {
        Object.keys(this.errors).forEach((key: string) => delete this.errors[key]);
        this.innerValue.periods?.splice(index, 1);
        this.$forceUpdate();
    }

    addPeriod() {
        let start, end;

        if (!this.innerValue.periods) {
            this.innerValue.periods = [];
            start = moment().add(1, "day").startOf("day").valueOf();
            end = moment().add(1, "day").add(1, "week").startOf("day").valueOf();
        } else {
            let p = this.innerValue.periods[this.innerValue.periods.length - 1];
            start = p.start;
            end = p.end;
        }
        this.innerValue.periods.push({
            start,
            end,
            slots: {
                all: this.getDefaultSlot(),
            },
        });
        this.$forceUpdate();
    }

    beforeMount() {
        if (!this.innerValue.periods?.length) {
            this.addPeriod();
        } else {
            this.innerValue.periods.forEach((period: MissionPeriod) => {
                if (period.slots) {
                    if (Object.keys(period.slots).length === 0) {
                        period.slots = {
                            all: this.getDefaultSlot(),
                        };
                    }

                    Object.keys(period.slots).forEach((key: string) => {
                        period.slots[key].forEach((slot: any) => {
                            if (typeof slot.from === "number") {
                                slot.from = getFormattedHour(slot.from);
                            }
                            if (typeof slot.to === "number") {
                                slot.to = getFormattedHour(slot.to);
                            }
                        });
                    });
                }
            });
        }
    }
}
