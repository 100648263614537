
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {IAgencyDB, IContact} from "@/types";
import clonedeep from "lodash.clonedeep";
import {showModal} from "@/helpers/callables";
import {Getter, State} from "vuex-class";
import AlertModal from "@/components/modal/AlertModal.vue";

interface IExtendedContact extends IContact {
    newContact?: boolean
}

@Component({
    name: "ContactForm",
    components: {AlertModal}
})
export default class ContactForm extends Vue {
    @Prop() value!: IContact[];

    @State("currentAgency") currentAgency!: IAgencyDB;
    @Getter('isInteraction') isInteraction!: Boolean;

    innerValue: IExtendedContact[] = [];
    showAlertModal = false;

    @Watch('innerValue', {deep: true})
    onInnerValue() {
        console.log("innerval: ", this.innerValue);
        this.$emit('input', this.innerValue.map(c => {
            return {
                firstname: c.firstname,
                lastname: c.lastname,
                role: c.role,
                phone: c.phone,
                email: c.email,
                evoliaOrder: c.evoliaOrder
            };
        }));
    }

    addContact() {
        const newContact = {
            firstname: '',
            lastname: '',
            phone: '',
            role: '',
            email: '',
            newContact: true
        };
        this.innerValue.push(newContact);
    }

    remove(index:number) {
        const contact = this.innerValue[index];
        if(contact && (!contact.newContact) && this.currentAgency && this.currentAgency.contractManagerIdentifier) {
            showModal.call(this, 'AlertModal');
            return;
        }

        this.innerValue.splice(index, 1);
    }

    beforeMount() {
        if (this.value) {
            this.innerValue = clonedeep(this.value);
        }
    }
}
