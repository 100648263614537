var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('perfect-scrollbar',{staticClass:"OrderCreationLocation",attrs:{"options":_vm.scrollOptions}},[_c('row',{staticClass:"rowAddress"},[_c('column',{attrs:{"d":"6"}},[_c('inline-field',{attrs:{"gmap":"","editonly":"","value":_vm.addressName,"label":"Lieux de mission"},on:{"placeChanged":_vm.placeChanged}}),_c('div',{staticClass:"map"},[_c('l-map',{ref:"map",staticClass:"map",attrs:{"bounds":_vm.getBounds,"options":{
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUi: false,
                        clickableIcons: false,
                        gestureHandling: 'none'
                        }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),(_vm.mapMarkerPosition)?_c('l-marker',{attrs:{"lat-lng":_vm.mapMarkerPosition}}):_vm._e(),_vm._l((_vm.companyMarkers),function(marker,index){return _c('l-marker',{key:index,attrs:{"lat-lng":marker}})})],2)],1)],1),_c('column',{attrs:{"d":"6"}},[(_vm.filteredAddresses && _vm.filteredAddresses.length)?_c('card',[_c('div',{staticClass:"CardTitle"},[(_vm.isAgency)?_c('span',[_vm._v(_vm._s(_vm.$t('page.orderCreation.steps.location.clientAddresses')))]):_vm._e(),(_vm.isCompany)?_c('span',[_vm._v(_vm._s(_vm.$t('page.orderCreation.steps.location.yourAddresses')))]):_vm._e()]),_c('perfect-scrollbar',{staticClass:"addresses"},_vm._l((_vm.filteredAddresses),function(address,index){return _c('inline-field',{key:index,class:_vm.isSelected(address) ? 'selected' : '',attrs:{"gmap":"","readonly":"","icon":'website',"iconClass":_vm.hasLocation(address) ? 'green' : 'red',"value":_vm.getAddressDisplay(address),"label":address.name},nativeOn:{"click":function($event){return _vm.labelClick(address)}}})}),1)],1):_vm._e()],1)],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }