
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import InterimLine from "@/components/interim/InterimLine.vue";
import draggable from "vuedraggable";
import { IAgencyDB, IBusinessSectorDB, IInterimDB, IUserDB, LabelValue, MissionPosition, StoreAction } from "@/types";
import { capitalize, formattedAddress, sortWorkers, toastError, capitalizeFirstLetter } from "@/helpers/commons";
import { Action, Getter, State } from "vuex-class";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import InterimMiniRead from "@/components/interim/InterimMiniRead.vue";
import { ESelectMultiple, EWorkerHabilitationType, MissionCommand, ROUTES, WorkerMissionStatus } from "@/data";
import Pagination from "@/components/Pagination.vue";
import Tile from "@/components/tileContent/Tile.vue";
import AgencyRead from "@/components/agency/AgencyRead.vue";
import AgencyPreview from "@/components/AgencyPreview.vue";
import AgencyTile from "@/components/tileContent/AgencyTile.vue";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import ActionButton from "../ActionButton.vue";
import NewIcon from "../NewIcon.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import Field from "../form/Field.vue";
import PositionJobOffer from "@/components/ats/PositionJobOffer.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

type Position = {
    quantity: number;
    name: string;
    workers: Array<{
        _id: string;
        fullname: string;
        phone: string;
        status: WorkerMissionStatus;
        availableCommands: Array<string>;
    }>;
};
@Component({
    name: "OrderCreationSelection",
    components: {
        AgencyTile,
        PositionJobOffer,
        AgencyPreview,
        AgencyRead,
        Tile,
        Pagination,
        InterimMiniRead,
        MissionTile,
        InterimLine,
        draggable,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        "v-marker-cluster": Vue2LeafletMarkerCluster,
        NewIcon,
        ActionButton,
        SelectAutocomplete,
        Field,
    },
})
export default class OrderCreationSelection extends mixins(OrderCreationStepMixin) {
    $refs!: any;
    loading = false;
    jobsLoading = false;
    innerLoading = false;
    sended = false;
    criteria: string[] = ["nearness", "reactivity", "mobility", "client", "activity", "reliability", "candidate"];
    icons: object = {
        nearness: "location-marker",
        reactivity: "thumb-up",
        mobility: "car",
        client: "clipboard",
        activity: "chart-pie",
        reliability: "statistic",
        candidate: "user",
    };
    currentState: string = "selected";
    workers: any = [];
    candidates: any = [];
    agencies: any = {
        advanced: [],
        starters: [],
        favorites: [],
    };
    capitalize = capitalize;
    capitalizeFirstLetter = capitalizeFirstLetter;
    formattedAddress = formattedAddress;
    mapView = false;
    winOpen = false;
    populatedAgency: IAgencyDB | null = null;
    windowPos: any = null;
    openedWorker: any = null;
    moving = false;
    populatingAgency = false;
    interimMoving: any = null;
    interimToAbort: any = null;
    highlightCoords: any = {};
    operator = "or";

    agencyToRead: any = null;

    pagination: any = {
        currentPage: 0,
        offset: 0,
        limit: 100,
        total: 0,
    };

    timeout: any = 0;
    timeout2: any = 0;
    query = "";
    selectedJobs: any = [];
    selectedItems: string[] = [];
    mutipleInfo = false;
    openAddJob: boolean = false;
    isOpenPopoverAddJobs: boolean = false;
    requestJobs: any = [];
    selectOption: ESelectMultiple = ESelectMultiple.NO_ONE;

    mode: string = "worker";

    url: string = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    attribution: string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    selectedDocuments: any = [];
    lastAgencyContactDate: "week" | "month" | null = null;

    @Getter("businessSectorOptions") businessSectorOptions!: LabelValue[];
    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("getJobOption") getJobOption!: any;
    @Getter("isAgency") isAgency!: boolean;
    @Getter("isCompany") isCompany!: boolean;
    @Getter("hasATS") hasATS!: boolean;
    @Getter("hasJobOffers") hasJobOffers!: boolean;
    @Getter("getWorkerDocuments") getWorkerDocuments!: any;
    @Getter("isInteraction") isInteraction!: boolean;

    @State("selectedCollaborators") selectedCollaborators!: IUserDB[];
    @State("selectedDomains") selectedDomains!: IBusinessSectorDB[];

    @Action("actions/searchMission") searchMission!: StoreAction;
    @Action("actions/searchCandidates") searchCandidates!: StoreAction;
    @Action("actions/loadMissionAgencies") loadMissionAgencies!: StoreAction;
    @Action("actions/sendCommandMission") sendCommandMission!: StoreAction;
    @Action("actions/loadAgency") loadAgency!: StoreAction;
    @Action("actions/noAgencyMatches") noAgencyMatches!: StoreAction;

    @Watch("selectedCollaborators", { deep: true })
    onCollabs() {
        this.search();
    }

    @Watch("selectedDomains", { deep: true })
    onDomains() {
        this.search();
    }

    @Watch("criteria")
    onCriteria() {
        this.search();
    }

    @Watch("pagination.currentPage")
    onPaginationPage() {
        this.search();
    }

    @Watch("selectedJobs.length")
    onSelectedJobs() {
        this.requestJobs = this.selectedJobs.map((job: any, idx: number) => {
            const newJob = this.requestJobs[idx];
            return {
                ...job,
                search: newJob ? newJob.search : true,
            };
        });
        this.search();
    }

    @Watch("selectedDocuments", { deep: true })
    onSelectedHabilitation() {
        this.search();
    }

    @Watch("lastAgencyContactDate")
    onSelectLastAgencyContactDate() {
        this.search();
    }

    get hasAskAgency() {
        return this.innerValue.availableCommands?.includes("askAgency");
    }

    get positions(): Array<Position> {
        return (
            this.innerValue.positions?.map((position: any, index: number) => {
                return {
                    quantity: position.quantity,
                    name: this.getJob(position.jobId)?.label,
                    jobOfferId: position.jobOfferId,
                    jobOffer: position.jobOffer,
                    jobId: position.jobId,
                    workers:
                        this.innerValue.workers
                            ?.map((worker: any) => {
                                return (
                                    worker.positionIndex === index && {
                                        _id: worker.workerId,
                                        fullname: capitalize(worker.firstname) + " " + capitalize(worker.lastname),
                                        phone: worker.phone,
                                        status: worker.status,
                                        contractId: worker.contractId,
                                        availableCommands: worker.availableCommands,
                                        registered: worker.registered,
                                        criteria: worker.criteria,
                                        picture: worker.picture,
                                        distance: worker.distance,
                                        jobs: worker.jobs,
                                        workerDocuments: worker.workerDocuments,
                                        warnings: worker.warnings,
                                    }
                                );
                            })
                            .filter((a: any) => a) || [],
                };
            }) || []
        );
    }

    get choosedAgency() {
        if (this.innerValue.agencyId) {
            if (this.populatedAgency) {
                return this.populatedAgency;
            } else {
                if (!this.populatingAgency) {
                    return this.populateAgency();
                }
            }
        }
    }

    get filteredWorkers() {
        return this.workers?.filter((worker: any) => {
            return !this.innerValue.workers?.find((w: any) => w.workerId === worker._id);
        });
    }

    get localizedCandidate() {
        return this.candidates.filter((candidate: any) => candidate.address);
    }

    get localizedWorkers() {
        return this.workers?.filter((worker: any) => worker.address?.location && worker.address?.location.length);
    }

    get favoriteAgencies() {
        return this.agencies.favorites;
    }

    get advancedAgencies() {
        return this.agencies.advanced;
    }

    get startersAgencies() {
        return this.agencies.starters;
    }

    get missionJobs() {
        return this.innerValue.positions?.map((p: any) => p.jobId) || [];
    }

    get isCandidates() {
        return this.mode == "candidates";
    }

    get isWorker() {
        return this.mode == "worker";
    }

    get habilitations() {
        return this.innerValue.positions;
    }

    get workerDocumentsOnMission(): any {
        let content: any = {
            [EWorkerHabilitationType.BTP_CARD]: [],
            [EWorkerHabilitationType.DEGREE]: [],
            [EWorkerHabilitationType.HABILITATION]: [],
            [EWorkerHabilitationType.MEDICAL_CHECK_UP]: [],
            [EWorkerHabilitationType.OFFICIAL_DOCUMENTS]: [],
        };

        this.innerValue.positions.forEach((position: MissionPosition) => {
            Object.entries(position.workerDocuments).forEach((docs: any) => {
                docs[1].forEach((doc: any) => {
                    if (content[doc.type].find((alreadyExistDoc: any) => alreadyExistDoc.value === doc.value)) return;
                    content[doc.type].push({
                        ...doc,
                        selected: true,
                        name: doc.label,
                        idDocument: doc.value,
                    });
                });
            });
        });

        this.selectedDocuments = content;

        return Object.entries(content);
    }

    hasMoreProfiles(mode: string) {
        return mode === "worker" ? this.workers.length === this.pagination.limit : this.candidates.length === this.pagination.limit;
    }

    latLng(position: any) {
        return (
            position &&
            position.length && {
                lat: parseFloat(<string>position[1].toString()),
                lng: parseFloat(<string>position[0].toString()),
            }
        );
    }

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i: LabelValue) => i.value === id);
    }

    getJob(id: string) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    setCurrentState(state: string) {
        this.currentState = state;
    }

    toggleMap() {
        this.mapView = !this.mapView;
        setTimeout(() => {}, 500);
    }

    activeWorkers(position: Position) {
        return position?.workers
            ?.filter((w) => {
                return (
                    w.status !== WorkerMissionStatus.aborted &&
                    w.status !== WorkerMissionStatus.rejectedByAgency &&
                    w.status !== WorkerMissionStatus.rejectedByCompany &&
                    w.status !== WorkerMissionStatus.declined
                );
            })
            .sort(sortWorkers);
    }

    openInfo(worker: any) {
        this.windowPos = this.latLng(worker.address.location);
    }

    startDragInterim(event: any, interim: IInterimDB) {
        if (this.selectedItems.length) {
            this.mutipleInfo = true;
        }
        this.interimMoving = interim;
        this.moving = true;
    }

    endDragInterim(event: any) {
        if (this.selectedItems.length) {
            this.mutipleInfo = false;
        }
        this.moving = false;
    }

    startDragInterimInJob(event: any, interim: IInterimDB) {
        this.interimToAbort = interim;
    }

    endDragInterimInJob(event: any) {}

    startHighlight(index: number) {
        this.highlightCoords[index] = true;
    }

    stopHighlight(index: number) {
        this.highlightCoords[index] = false;
        this.$forceUpdate();
    }

    emitCommand(result: any) {
        this.$emit("command", result);
    }

    openMiniRead(agency: IAgencyDB) {
        this.agencyToRead = agency;
    }

    async populateAgency() {
        this.populatingAgency = true;
        try {
            if (this.innerValue.agencyId) {
                this.populatedAgency = await this.loadAgency(this.innerValue.agencyId);

                if (this.populatedAgency && this.populatedAgency.survey) {
                    const survey: any = this.populatedAgency.survey;

                    if (survey) {
                        if (survey.mainBusinessSectors) {
                            survey.mainBusinessSectors = survey.mainBusinessSectors.map((i: string) => this.getBusinessSector(i));
                        }
                        if (survey.developingBussinessSectors) {
                            survey.developingBussinessSectors = survey.developingBussinessSectors.map((i: string) => this.getBusinessSector(i));
                        }
                        if (survey.jobsRequiringMissions) {
                            survey.jobsRequiringMissions = survey.jobsRequiringMissions.map((i: string) => this.getJob(i));
                        }
                        if (survey.jobsRequiringWorkers) {
                            survey.jobsRequiringWorkers = survey.jobsRequiringWorkers.map((i: string) => this.getJob(i));
                        }
                        this.$set(this.populatedAgency, "survey", survey);
                    }
                }
            }
            this.populatingAgency = false;
            return this.populatedAgency;
        } catch (e) {
            console.log(e);
        }
        this.populatingAgency = false;
    }

    async itemDropped(event: any, index: number) {
        this.highlightCoords = {};
        this.loading = true;

        try {
            if (this.interimMoving) {
                if (!this.selectedItems.includes(this.interimMoving._id)) {
                    this.selectedItems.push(this.interimMoving._id);
                }
                await this.sendCommand(MissionCommand.addInterim, index, this.selectedItems);
                this.selectedItems = [];
            }
            this.interimMoving = null;
            this.selectOption = ESelectMultiple.NO_ONE;
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async abortInterim() {
        try {
            if (this.interimToAbort) {
                await this.sendCommand(MissionCommand.workerAbort, -1);
            }
            this.interimToAbort = null;
        } catch (e) {
            console.log(e);
        }
    }

    async sendCommand(command: string, index: number, item?: any) {
        this.jobsLoading = true;
        try {
            const id = item ? item : this.interimMoving ? this.interimMoving._id : this.interimToAbort ? this.interimToAbort._id : "";

            if (id) {
                const data: any = {
                    workerId: id,
                };

                if (typeof index === "number" && index >= 0) {
                    data.positionIndex = index;
                }

                if (this.interimMoving && this.interimMoving.criteria) {
                    data.criteria = this.interimMoving.criteria;
                }
                if (this.interimMoving && this.interimMoving.picture) {
                    data.picture = this.interimMoving.picture;
                }
                const result = await this.sendCommandMission({
                    missionId: this.innerValue._id,
                    command,
                    data,
                });
                this.emitCommand(result);
            }
        } catch (e) {
            console.log(e);
            // @ts-ignore;
            this.$toast.open({
                message: toastError(e),
                type: "error",
                duration: 10000,
            });
        }
        this.jobsLoading = false;
    }

    searchSelection(query: string) {
        this.query = query;
        this.selectedItems = [];
        this.selectOption = ESelectMultiple.NO_ONE;
        if (this.timeout2) {
            clearTimeout(this.timeout2);
        }

        this.timeout2 = setTimeout(() => {
            this.search();
        }, 300);
    }

    onSelect(data: any) {
        if (!data.dataId) return;

        if (!data.selected) {
            const idx = this.selectedItems.indexOf(data.dataId);
            this.selectedItems.splice(idx, 1);
            return;
        }

        this.selectedItems.push(data.dataId);
    }

    async search() {
        this.loading = true;
        this.workers = [];
        try {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            if (this.selectedJobs?.length) {
                const jobsSearch = this.requestJobs.filter((job: any) => job.search);
                this.timeout = setTimeout(async () => {
                    try {
                        if (this.isAgency && this.selectedJobs?.length) {
                            const result = await this.searchMission({
                                missionId: this.innerValue._id,
                                data: {
                                    pagination: {
                                        offset: this.pagination.currentPage * this.pagination.limit,
                                        limit: this.pagination.limit,
                                    },
                                    criteria: this.criteria,
                                    text: this.query,
                                    jobs: jobsSearch.map((a: any) => a.value),
                                    requestedDocuments: this.selectedDocuments,
                                    jobOperator: this.operator,
                                    lastAgencyContactDate: this.lastAgencyContactDate,
                                },
                            });

                            this.pagination.total = result.pagination.total;
                            this.workers = result.interims;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                    this.loading = false;
                }, 200);
            } else {
                this.loading = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    sendQuote(agency: IAgencyDB) {
        return async () => {
            try {
                if (agency) {
                    const result = await this.sendCommandMission({
                        missionId: this.innerValue._id,
                        command: MissionCommand.askAgency,
                        data: { agencyId: agency._id },
                    });
                    this.emitCommand(result);
                    this.$set(agency, "quote", true);
                    this.$forceUpdate();
                    this.$router.push({ name: ROUTES.APP.MISSION._ROOT, params: { missionId: this.innerValue._id } });
                }
            } catch (e) {
                console.log(e);
            }
        };
    }

    changeOperator(operator: string) {
        this.operator = operator;
        this.init();
    }

    fillAgencies(agencies: any) {
        agencies.forEach((a: any) => {
            const survey: any = a.survey;

            if (survey) {
                if (survey.mainBusinessSectors) {
                    survey.mainBusinessSectors = survey.mainBusinessSectors.map((i: string) => this.getBusinessSector(i));
                }
                if (survey.developingBussinessSectors) {
                    survey.developingBussinessSectors = survey.developingBussinessSectors.map((i: string) => this.getBusinessSector(i));
                }
                if (survey.jobsRequiringMissions) {
                    survey.jobsRequiringMissions = survey.jobsRequiringMissions.map((i: string) => this.getJob(i));
                }
                if (survey.jobsRequiringWorkers) {
                    survey.jobsRequiringWorkers = survey.jobsRequiringWorkers.map((i: string) => this.getJob(i));
                }
            }

            this.$set(a, "survey", survey);

            a.quote = false;
        });
    }

    async sendToWizim() {
        this.innerLoading = true;
        try {
            await this.noAgencyMatches(this.innerValue._id);
            this.sended = true;
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async init() {
        try {
            await this.search();
        } catch (e) {
            console.log(e);
        }
    }

    seeMoreProfiles() {
        this.pagination.limit += 100;
        this.init();
    }

    beforeMount() {
        this.noWorkerDocumentFiltering();

        if (this.innerValue.searchedJobs?.length) {
            this.selectedJobs = this.innerValue.searchedJobs.map((a: any) => this.getJobOption(a));
        } else if (this.innerValue.positions?.length) {
            this.selectedJobs = this.innerValue.positions.map((a: any) => this.getJobOption(a.jobId));
        }

        this.requestJobs = this.selectedJobs.map((job: any) => {
            return {
                ...job,
                search: true,
            };
        });

        this.selectedDocuments = "";

        this.init();
    }

    getBounds() {
        const bounds: Array<any> = [];

        for (let worker of this.localizedWorkers) {
            bounds.push([this.latLng(worker.address.location)]);
        }

        for (let candidate of this.localizedCandidate) {
            bounds.push([this.latLng(candidate.address.location)]);
        }

        return latLngBounds(bounds);
    }

    jobsLabel() {
        const jobsSearch = this.requestJobs.filter((job: any) => job.search);

        if (jobsSearch.length === 1) {
            return jobsSearch[0].label;
        }

        if (jobsSearch.length === 0) {
            return this.$t("page.orderCreation.steps.selection.noJob");
        }
        return jobsSearch[0].label + " , +" + (jobsSearch.length - 1);
    }

    get filteredJobs() {
        return this.jobOptions.filter((a: any) => this.selectedJobs.indexOf(a.value) === -1);
    }

    get operatorOptions() {
        return [
            {
                label: "Ou",
                value: "or",
            },
            {
                label: "Et",
                value: "and",
            },
        ];
    }

    changeJobSearch() {
        this.jobsLabel();
        this.search();
    }

    closePopover() {
        this.openAddJob = false;
        this.isOpenPopoverAddJobs = false;
    }

    noWorkerDocumentFiltering(): boolean {
        return this.innerValue.positions.every((position: any) => {
            return Object.values(position.workerDocuments)
                .flat()
                .every((doc: any) => {
                    console.log(doc), doc.length === 0;
                });
        });
    }

    clickCheckoboxMultiSelect() {
        if (this.selectedItems.length === 0) {
            for (let i = 0, len = this.filteredWorkers.length; i < len; ++i) {
                if (!this.selectedItems.includes(this.filteredWorkers[i]._id)) {
                    this.selectedItems.push(this.filteredWorkers[i]._id);
                }
            }
            this.selectOption = ESelectMultiple.ALL;
        } else {
            this.selectedItems = [];
            this.selectOption = ESelectMultiple.NO_ONE;
        }
    }

    get allSelected() {
        return this.selectedItems.length === this.filteredWorkers.length && this.filteredWorkers.length > 0;
    }

    get partialSelected() {
        return this.selectedItems.length > 0 && this.selectedItems.length < this.filteredWorkers.length;
    }

    get isItemSelected() {
        return this.allSelected || this.partialSelected;
    }

    get sortOptions() {
        return [ESelectMultiple.NO_ONE, ESelectMultiple.ALL, ESelectMultiple.TOP_TEN, ESelectMultiple.TOP_TWENTY, ESelectMultiple.TOP_FIFTY].map(
            (o) => {
                return { label: o, value: o };
            }
        );
    }

    multipleSelectChanged(select: string) {
        switch (select) {
            case ESelectMultiple.NO_ONE:
                this.selectedItems = [];
                break;
            case ESelectMultiple.ALL:
                this.clickCheckoboxMultiSelect();
                break;
            case ESelectMultiple.TOP_TEN:
                this.selectMultipleWorkers(10);
                break;
            case ESelectMultiple.TOP_TWENTY:
                this.selectMultipleWorkers(20);
                break;
            case ESelectMultiple.TOP_FIFTY:
                this.selectMultipleWorkers(50);
                break;
        }
    }

    selectMultipleWorkers(numberWorkers: number) {
        this.selectedItems = [];
        if (this.filteredWorkers.length < numberWorkers) {
            numberWorkers = this.filteredWorkers.length;
        }
        for (let i = 0; i < numberWorkers; ++i) {
            if (!this.selectedItems.includes(this.filteredWorkers[i]._id)) {
                this.selectedItems.push(this.filteredWorkers[i]._id);
            }
        }
    }

    get profilFoundOrSelect() {
        if (this.selectedItems.length > 0) {
            return this.selectedItems.length + "-" + this.filteredWorkers.length + " sélectionné(s)";
        }
        return this.filteredWorkers.length + " profils";
    }
}
