
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Getter} from "vuex-class";

@Component({
    name: "OrderBreadcrumb"
})
export default class OrderBreadcrumb extends Vue {
    @Prop() readonly steps!: any;
    @Prop() readonly currentStep!: number;
    @Prop() readonly realCurrentStep!:number;
    @Prop() readonly action!:any;
    @Prop() readonly isLock!: boolean;
    @Getter('isAgency') isAgency!: any;
    @Getter('isCompany') isCompany!: any;

    get scrollOptions() {
        return {
            suppressScrollY: true,
            scrollYMarginOffset: 20
        }
    }

    get filteredSteps() {
        return this.steps.filter((s:any) => !s.invisible);
    }

    get nbInvisible() {
        return this.steps.filter((s:any) => s.invisible).length;
    }

    get visible() {
        return this.isAgency
            || (this.isCompany && this.currentStep < (this.steps.length - this.nbInvisible));
    }

    get realVisible() {
        let n = this.realCurrentStep - this.nbInvisible;

        return n < 0 ? 0 : n;
    }

    nextStep() {
        this.$emit('next');
    }

    stepSelected(index:number) {
        if (this.realCurrentStep >= index) {
            this.$emit('stepSelected', index);
        }
    }
}
