
    import { Component, Prop } from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import VModel from "@/mixins/VModel.vue";
    import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
    import {IAddress} from "@/types";
    import {formattedAddress} from "@/helpers/commons";
import { Getter } from 'vuex-class';
import GeocodeInput from './form/v3/GeocodeInput.vue';

@Component({
    name: "AddressForm",
    components: { GeocodeInput, InputGmapAutocomplete }
})
export default class AddressForm extends mixins(VModel) {
    @Prop(String) forcedName?: string;
    @Prop(Boolean) noRemove?: boolean;
    @Prop() evoliaAddr!: IAddress;

    @Getter('isInteraction') ievoliaAddresssInteraction!: boolean;

    get evoliaAddress() {
        return formattedAddress(this.evoliaAddr);
    }

    get hasLocation() {
        return this.innerValue.location?.length === 2;
    }

    addressValue(address:IAddress) {
        return formattedAddress(address);
    }
    placeChanged(place: any) {
        this.innerValue = {
            name: this.innerValue.name,
            street: [place.street_number, place.street].filter(s => s).join(' '),
            complement: place.complement || this.innerValue?.complement,
            city: place.locality,
            zip: place.zip,
            countryCode: place.countryCode,
            location: [place.longitude, place.latitude]
        }
    }

    remove() {
        this.$emit('remove');
    }
}
