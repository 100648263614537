
import { Component, Prop } from 'vue-property-decorator';
import { IAddress, IUserDB, LabelValue } from "@/types";
import ContactForm from "@/components/ContactForm.vue";
import NumbersForm from "@/components/NumbersForm.vue";
import AddressesForm from "@/components/AddressesForm.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import { Getter, State } from "vuex-class";
import InlineField from "@/components/InlineField.vue";
import { mandatoryField } from "@/helpers/commons";

@Component({
    name: "ClientForm",
    components: {InlineField, PicturePreview, AddressesForm, NumbersForm, ContactForm}
})
export default class ClientForm extends mixins(VModel) {
    $refs!: {
        clientName: any,
        map: any
    }

    @Prop() errors!: any;
    @Prop() globalErrors!: any;
    @Prop(Boolean) centeredPicture!: boolean;

    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('collaboratorsOptions') collaboratorsOptions!: LabelValue[];
    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;
    @State('currentUser') currentUser!: IUserDB;
    @State('collaborators') collaborators!: IUserDB[];
    @Getter('currentUserId') currentUserId!: string;
    @Getter('isInteraction') isInteraction!: boolean;

    mandatoryField = mandatoryField;

    edit: any = {
        clientName: false
    };

    locatedAddresses: any = [];

    get collaborator() {
        if (this.innerValue.collaboratorId) {
            return this.collaborators.find((c:IUserDB) => c._id === this.innerValue.collaboratorId);
        }
    }

    get bsOptions() {
        return this.businessSectorOptions
    }

    get collaboratorsWithMe() {
        return [
            {
                label: "Moi",
                value: this.currentUserId
            },
            ...this.collaboratorsOptions
        ]
    }

    get isCreationMode(): boolean {
        return this.value._id === undefined;
    }

    recalculLocatedAddresses() {
        this.locatedAddresses = this.innerValue?.addresses?.filter((address: IAddress) => {
            return address?.location?.length;
        }).map((address:IAddress) => {
            return {
                ...address,
                location: this.latLng(address.location)
            }
        });

        setTimeout(() => {
            this.boundMap();
        }, 200)
    }

    latLng(position:any) {
        return position && {
            lat: parseFloat(<string>position[1].toString()),
            lng: parseFloat(<string>position[0].toString())
        };
    }

    boundMap() {
        // @ts-ignore;
        if (window.google) {
            // @ts-ignore;
            const bounds = new google.maps.LatLngBounds()

            this.innerValue?.addresses?.forEach((address: IAddress) => {
                if (address?.location?.length) {
                    bounds.extend(this.latLng(address.location));
                }
            });

            if (bounds && this.$refs.map) {
                this.$refs.map.fitBounds(bounds);
                this.$refs.map.panToBounds(bounds);
            }
        }
    }

    editName() {
        this.edit.clientName = true
        this.$nextTick(() => {
            this.$refs.clientName.$el.querySelector('input').focus();
        });
    }

    stopEditName() {
        this.edit.clientName = false;
    }

    mounted() {
        this.boundMap();
    }

    beforeMount() {
        if (!this.innerValue.addresses) {
            this.innerValue.addresses = [];
        }
        if (!this.innerValue.mainAddress) {
            this.innerValue.mainAddress = {};
        }
        if (!this.innerValue.contacts) {
            this.innerValue.contacts = [];
        }
        if (!this.innerValue.numbers) {
            this.innerValue.numbers = [];
        }
        if (!this.innerValue.collaboratorId) {
            this.innerValue.collaboratorId = this.currentUser._id;
        }

        if (!this.innerValue.socialNetworks) {
            this.innerValue.socialNetworks = {
                facebook: "",
                twitter: "",
                linkedin: "",
                instagram: ""
            };
        }
        this.innerValue.agencyId = this.currentUser.agencyId;
    }
}
