
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "TimetableWarning"
    })
    export default class TimetableWarning extends mixins(ModalMixin) {
        name = "TimetableWarning";

        @Prop() dateErrors!: any;

        get indexes() {
            return this.dateErrors.periodIndex + 1;
        }
    }
