
import {Component, Prop} from 'vue-property-decorator';
import {IAddress, IAgencyDB} from "@/types";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import AddressForm from "@/components/AddressForm.vue";
import {State} from "vuex-class";
import {showModal} from "@/helpers/callables";
import AlertModal from "@/components/modal/AlertModal.vue";

@Component({
    name: "AddressesForm",
    components: {AlertModal, AddressForm, InputGmapAutocomplete}
})
export default class AddressesForm extends mixins(VModel) {
    @Prop() errors!:any;
    @Prop() evoliaAddresses!: Array<IAddress>;

    @State('currentAgency') currentAgency!: IAgencyDB;

    showAlertModal = false;

    addAddress() {
        this.innerValue.push({
            name: '',
            street: '',
            complement: '',
            city: '',
            zip: '',
            countryCode: '',
            location: [],
            newAddress: true
        });
    }

    remove(index:number) {
        if(this.innerValue[index] && (!this.innerValue[index].newAddress) && this.currentAgency && this.currentAgency.contractManagerIdentifier) {
            showModal.call(this, 'AlertModal');
            return;
        }

        this.innerValue.splice(index, 1);
    }
}
