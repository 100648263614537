
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import InlineField from "@/components/InlineField.vue";
import Tile from "@/components/tileContent/Tile.vue";
import OrderTile from "@/components/tileContent/OrderTile.vue";
import clonedeep from "lodash.clonedeep";
import {IAddress, IGeoLocation} from "@/types";
import {formattedAddress} from "@/helpers/commons";
import {Getter, State} from "vuex-class";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { latLngBounds } from "leaflet";
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
    name: "OrderCreationLocation",
    components: {OrderTile, Tile, InlineField,
        LMap, LTileLayer, LMarker}
})
export default class OrderCreationLocation extends mixins(OrderCreationStepMixin) {

    center:any = {lat: 48.845617, lng: 2.2431133};
    addressName="";

    mapMarkerPosition:any = null;

    url:string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

    attribution:string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'

    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;
    @State('currentCompany') currentCompany!: any;

    get companyMarkers() {
        const filteredAddresses = this.innerValue.client?.addresses?.filter((address:IAddress) => address.location && address.location[0] && address.location[1])
        if (filteredAddresses.length <= 0) return [];

        return filteredAddresses.map((address:IAddress) => {
            return { lat: address.location![1], lng: address.location![0] };
        });
    }

    get getBounds() {
        if (!this.mapMarkerPosition) {
            const bounds: Array<any> = this.companyMarkers.map((marker: any) => [marker.lat, marker.lng]);
            return bounds.length > 0 ? latLngBounds(bounds) : latLngBounds([[this.center.lat, this.center.lng]]);
        }

        return latLngBounds([this.mapMarkerPosition]);
    }

    get filteredAddresses() {
        return this.isCompany ? this.currentCompany.addresses : this.innerValue?.client?.addresses;
    }

    labelClick(address:IAddress) {
        if (!this.innerValue.address) this.innerValue.address = {};
        if (address) {
            this.innerValue.address = { ...address };
            if (this.innerValue.address.location?.length) {
                this.mapMarkerPosition = {
                    lat: this.innerValue.address.location[1],
                    lng: this.innerValue.address.location[0]
                };
                //@ts-ignore
                this.$refs.map.mapObject.setView({
                        lat: this.innerValue.address.location[1],
                        lng: this.innerValue.address.location[0]
                    }, 16)
            }
            this.addressName = formattedAddress(this.innerValue.address);
        }
    }

    placeChanged(place:any) {
        if (!this.innerValue.address) {
            this.innerValue.address = {};
        }
        if (this.innerValue.address) {
            const { street, complement, locality, postal_code, countryCode, longitude, latitude } = place;
            this.innerValue.address = {
                name: "__",
                street,
                complement,
                city: locality,
                zip: postal_code,
                countryCode,
                location: [ longitude, latitude ]
            }
            if (this.innerValue.address.location?.length) {
                this.mapMarkerPosition = {
                    lat: this.innerValue.address.location[1],
                    lng: this.innerValue.address.location[0]
                };
            } else {
                this.mapMarkerPosition = null;
            }
            this.addressName = formattedAddress(this.innerValue.address);
        }
        this.$forceUpdate();
    }

    getAddressDisplay(address:IAddress) {
        return formattedAddress(address);
    }

    isSelected(address:IAddress) {
        if (this.innerValue.address) {
            return this.innerValue.address.street === address.street
                && this.innerValue.address.city === address.city
                && this.innerValue.address.zip === address.zip;
        }
    }

    hasLocation(address:any) {
        return address?.location && address.location.length === 2;
    }

    beforeMount() {
        this.$set(this.innerValue, 'address', { ...this.innerValue.address });
        if (this.innerValue.address) {
            this.addressName = formattedAddress(this.innerValue.address);
        }
        if (this.innerValue.address?.location?.length) {
            this.mapMarkerPosition = {lat: this.innerValue.address.location[1], lng: this.innerValue.address.location[0]};
        }
        this.$forceUpdate();
    }
}
