
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {KeyFigure} from "@/types";

@Component({
    name: "NumbersForm"
})
export default class NumbersForm extends Vue {
    innerValue: KeyFigure[] = [];

    @Prop() value!: KeyFigure[];
    @Prop() readonly!: boolean;

    @Watch('innerValue')
    onInnerValue() {
        this.$emit('input', this.innerValue);
    }

    addNumber() {
        this.innerValue.push({
            label: '',
            value: ''
        })
    }

    remove(index:number) {
        this.innerValue.splice(index, 1);
    }

    beforeMount() {
        if (this.value) {
            this.innerValue = this.value;
        }
    }
}
