
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "DateWarning"
    })
    export default class DateWarning extends mixins(ModalMixin) {
        name = "DateWarning";

        @Prop() dateErrors!: any;

        get indexes() {
            let idx:any = {a: -1, b: -1};

            Object.keys(this.dateErrors || {}).forEach((key:string) => {
                if (this.dateErrors[key]?.period) {
                    if (idx.a === -1) {
                        idx.a = parseInt(key) + 1;
                    } else if (idx.b === -1) {
                        idx.b =  parseInt(key) + 1;
                    }
                }
            });

            return idx;
        }
    }
