
import { Component, Vue, Prop } from "vue-property-decorator";
import Tile from "@/components/tileContent/Tile.vue";
import { ICompanyDB } from "@/types";
import moment from "moment-timezone";

@Component({
    name: "CompanyTile",
    components: { Tile },
})
export default class CompanyTile extends Vue {
    @Prop() readonly company!: ICompanyDB;
    @Prop() readonly mode!: string;

    moment = moment;

    get isGridMode() {
        return this.mode === "grid";
    }
}
